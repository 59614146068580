const state = {
  company: null,
  report: false,
  drawer: false,
  excelReport: false
};

const mutations = {
  setCompany(state, { id }) {
    state.company = id;
  },
  setReport(state, { val }) {
    state.report = val;
  },
  setExcelReport(state, { val }) {
    state.excelReport = val;
  },
  unsetData(state) {
    state.company = null;
    state.report = false;
  },
  TOGGLE_DRAWER(state) {
    state.drawer = !state.drawer;
  },
  OPEN_DRAWER(state) {
    state.drawer = true;
  },
  CLOSE_DRAWER(state) {
    state.drawer = false;
  }
};

const actions = {
  setCompany({ commit }, data) {
    commit('setCompany', data);
  },
  setReport({ commit }, data) {
    commit('setReport', data);
  },
  setExcelReport({ commit }, data) {
    commit('setExcelReport', data);
  },
  clearData({ commit }) {
    commit('unsetData');
  },
  toggleDrawer({ commit }) {
    commit('TOGGLE_DRAWER');
  },
  openDrawer({ commit }) {
    commit('OPEN_DRAWER');
  },
  closeDrawer({ commit }) {
    commit('CLOSE_DRAWER');
  }
};

const getters = {
  company: (state) => state.company
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
